export const experienceData = [
    {
        id: 1,
        company: 'Yalla najma',
        jobtitle: 'Founder',
        startYear: '2021',
        endYear: 'Present'
    },
    {
        id: 2,
        company: 'Mostaql',
        jobtitle: 'Freelancer',
        startYear: '2022',
        endYear: 'Present'
    },
    {
        id: 3,
        company: 'Atlecs',
        jobtitle: 'Mobile Developer (Intership)',
        startYear: '2024',
        endYear: '2024'
    },
]